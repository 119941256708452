import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
import Account from '../../../modules/account/MyProfile';
import Dashboard from '../../../modules/dashboard';
import FaceCheck from '../../../modules/faceCheck';
import FaceQBS from '../../../modules/faceQBS';

export const accountPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
    path: '/my-profile',
    element: <Account />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
    path: ['/dashboard/', '/dashboard/:schoolId', '/dashboard/:schoolId/:classId'],
    element: <Dashboard />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
    path: ['/face-check/', '/face-check/:schoolId', '/face-check/:schoolId/:classId'],
    element: <FaceCheck />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
    path: ['/face-qbs/','/face-qbs/:schoolId/', '/face-qbs/:schoolId/:type', '/face-qbs/:schoolId/:type/:id'],
    element: <FaceQBS />,
  },
];
