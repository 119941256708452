import {
  GET_FACE_CHECK,
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  faces: [],
  class: []
};

const faceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_FACE_CHECK, (state, action) => {
      const { name, data, count } = action.payload;
      const index = state.faces.findIndex(item => item.key === name);
      if (index > -1) {
        state.faces[index].items = data;
        state.faces[index].count = count;
      } else {
        state.faces.push({
          key: name,
          items: data,
          count: count
        });
      }
    })
});

export default faceReducer;
