import {
  GET_FACE_QBS,
  GET_FACE_QBS_CLASS
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  faces: [],
  class: [],
  cameras: [],
};

const faceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_FACE_QBS, (state, action) => {
      // const index = state.faces.findIndex(item => item.studentId == action.payload.studentId && item.classId == action.payload.classId);
      // if (index == -1)
      //   state.faces = [
      //     action.payload,
      //     ...state.faces
      //   ];
      //   else {
      //     state.faces[index] = action.payload
      //   }
      state.faces = [
        action.payload,
        ...state.faces
      ];
      
        const indexClass = state.class.findIndex(item => item == action.payload.classId);
        if (indexClass == -1)
          state.class = [
            action.payload.classId,
            ...state.class
          ];
        const indexCamera = state.cameras.findIndex(item => item == action.payload.cameraName);
        if (indexCamera == -1)
          state.cameras = [
            action.payload.cameraName,
            ...state.cameras
          ];
    })
    .addCase(GET_FACE_QBS_CLASS, (state, action) => {
    
      const index = state.class.findIndex(item => item == action.payload);
      if (index == -1)
        state.class = [
          action.payload,
          ...state.class
        ];
    })
});

export default faceReducer;
