import {
  CREATE_NEW_SCHOOL,
  GET_SCHOOL_DETAIL,
  GET_SCHOOL_LIST,
  GET_SCHOOL_SETTINGS,
  UPDATED_SCHOOL
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
  selectedSchool: null,
  schoolSettings: null
};

const schoolReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_SCHOOL_LIST, (state, action) => {
      state.items = action.payload.items ?? [];
      state.count = action.payload.count ?? 0;
    })
    .addCase(CREATE_NEW_SCHOOL, (state, action) => {
      state.items = [action.payload, ...state.items];
      state.count = state.count + 1;
    })
    .addCase(GET_SCHOOL_DETAIL, (state, action) => {
      state.selectedSchool = action.payload;
    })
    .addCase(UPDATED_SCHOOL, (state, action) => {
      const selectedSchool = action.payload;
      state.selectedSchool = selectedSchool;
      const index = state.items.findIndex(item => item._id === selectedSchool._id);
      if (index > -1) {
        const nextList = [...state.items];
        nextList[index] = selectedSchool;
        state.items = nextList;
      }
    })
    .addCase(GET_SCHOOL_SETTINGS, (state, action) => {
      state.schoolSettings = action.payload;
    })
});

export default schoolReducer;
