import React from 'react';
import AppCard from '@crema/components/AppCard';
import Avatar from '@mui/material/Avatar';
import { alpha, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { getFormattedDate } from '@crema/helpers/DateHelper';
import RecentShoolMenu from './Menu';

const ProfilePic = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    border: `solid 2px ${alpha(theme.palette.primary.main, 0.7)}`,
    padding: 4,
    borderRadius: '50%',
    marginBottom: 10,
    marginTop: 10,
    '& .avatar-pic': {
      height: 84,
      width: 84,
    },
  };
});

const RecentSchoolCell = ({ data }) => {

  return (
    data &&
    <AppCard
      action={<RecentShoolMenu schoolId={data?.qbsID} />}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ProfilePic>
          <Avatar className='avatar-pic' src={data.logoPath} />
        </ProfilePic>
        <Box sx={{ mb: 0.5 }} component='h5'>
          {`${data?.name}`}
        </Box>
        <Box
          sx={{
            mb: 4,
            fontSize: 12,
            color: 'text.secondary',
          }}
        >
          {data?.qbsID}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                py: 3.5,
                px: 2,
                width: 1,
              }}
            >
              <Box component='p' color='text.secondary'>
                {getFormattedDate(data.updatedAt, 'DD/MM/YYYY HH:mm:ss')}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppCard >
  );
};

export default RecentSchoolCell;

RecentSchoolCell.propTypes = {
  data: PropTypes.object.isRequired,
};
