import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Box, Grid, Typography } from '@mui/material';
import FaceCameraItem from './FaceCameraItem';
import AppTooltip from "@crema/components/AppTooltip";
import IntlMessages from "@crema/helpers/IntlMessages";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fonts } from "@crema/constants/AppEnums";
import { useNavigate } from 'react-router-dom';
import AppCard from '@crema/components/AppCard';
import { useIntl } from 'react-intl';
import AppMenu from '@crema/components/AppMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetFaceQBS } from "toolkit/actions";
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Event from "socket/Event";

const FaceCamera = ({ schoolId, cameraId }) => {
  const navigate = useNavigate();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const faces = useSelector(({ faceQBS }) => faceQBS.faces);

  const faceData = faces.filter(item => item.cameraName == cameraId) || [];
  
  const onGetNewRecord = (dataEvent) => {
    dispatch(onGetFaceQBS(dataEvent));

  }


  const onClickBackButton = () => {
    navigate(-1);
  };

  return (
    <>
      <Event event={`qbs_face_${schoolId}_${cameraId}`} handler={(data) => onGetNewRecord(data)} />
      <Box
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          display: 'flex',
          marginBottom: 1
        }}
        component='span'
        mr={{ xs: 2, sm: 4 }}
        onClick={onClickBackButton}
      >
        <AppTooltip title={<IntlMessages id='common.back' />}>
          <ArrowBackIcon
            sx={{
              color: 'text.secondary',
            }}
          />
        </AppTooltip>

        <Typography
          component='h2'
          variant='h2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 3.5,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
            mb: { xs: 3, sm: 0 },
          }}
        >
          {<IntlMessages id='common.back' />}
        </Typography>
      </Box>
      <AppCard sx={{
        my: 2,
        py:0
      }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ mr: 4, textTransform: 'uppercase' }} component='h1'>
            {`Camera: ${cameraId}`}
          </Box>
          <Box
            sx={{
              mb: 1,
              fontSize: '2rem',
              color: 'text.secondary',
            }}
          >
            {`${faceData.length}`}
          </Box>
        </Box>
      </AppCard>
      <AppGridContainer>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={-1}>

          <FaceCameraItem data={faceData} />

        </Grid>
        
      </AppGridContainer>
    </>
  );
};

export default FaceCamera;

FaceCamera.propTypes = {
  schoolId: PropTypes.string,
  cameraId: PropTypes.string,
};
