import React from 'react';
import AppCard from '@crema/components/AppCard';
import Avatar from '@mui/material/Avatar';
import { alpha, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { getFormattedDate } from '@crema/helpers/DateHelper';
import { Fonts } from '@crema/constants/AppEnums';

const ProfilePic = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    border: `solid 2px ${alpha(theme.palette.primary.main, 0.7)}`,
    padding: 4,
    borderRadius: '50%',
    marginBottom: 10,
    marginTop: 10,
    '& .avatar-pic': {
      height: 84,
      width: 84,
    },
  };
});

const FaceCell = ({ face }) => {
  return (
    face &&
    <AppCard
      xs={{
        p: 0,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
        }}
      >
        <ProfilePic>
          <Avatar className='avatar-pic' src={`data:image/jpeg;base64,${face?.faceImages[0]?.image1}`} />
        </ProfilePic>
        <Box sx={{
          mb: 0.5,
          fontWeight: 'bold',
          fontSize: '1.2rem',
          textTransform: 'capitalize',
        }} component='h5'>
          {face?.name}
        </Box>
        <Box
          sx={{
            mb: 2,
            fontSize: 12,
            color: 'text.secondary',
          }}
        >
          {face?.job}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                py: 0,
                px: 2,
                width: 1,
                fontWeight: 'bold',
              }}
            >
              {getFormattedDate(face.updatedAt, 'DD/MM/YYYY HH:mm:ss')}
            </Box>
          </Box>
        </Box>
      </Box>
    </AppCard>
  );
};

export default FaceCell;

FaceCell.propTypes = {
  face: PropTypes.object.isRequired,
};
