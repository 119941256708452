import {
  CREATE_NEW_SCHOOL,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SCHOOL_DETAIL,
  GET_SCHOOL_LIST,
  GET_SCHOOL_SETTINGS,
  SET_DASHBOARD_CAMERAS,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_SCHOOL
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';

export const onGetSchoolList = ({ name = '', currentPage, limit }) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : 0;
  const take = limit ? limit : 10;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('schools', {
        params: {
          name,
          page,
          take
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_SCHOOL_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateSchool = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('schools', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_SCHOOL, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['school.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onDeleteSelectedCompanies = (schoolIds = []) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await schoolIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`schools/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetSchoolList({ name: '' }))
  };
};

export const onGetSchoolDetail = (schoolId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`schools/${schoolId}`)).data;
      dispatch({ type: GET_SCHOOL_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateSchool = (schoolId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`schools/${schoolId}`, payload)).data;
      dispatch({ type: GET_SCHOOL_DETAIL, payload: data });
      dispatch({ type: UPDATED_SCHOOL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['school.created'],
      });
      dispatch(showMessage(messages['school.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onGetSchoolSettings = (schoolId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`school-settings/${schoolId}`)).data;
      dispatch({ type: GET_SCHOOL_SETTINGS, payload: data });
      dispatch({ type: SET_DASHBOARD_CAMERAS, payload: data.cameras });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
}

export const onUpdateSettings = (schoolId = '', payload = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`school-settings/${schoolId}`, payload)).data;
      dispatch({ type: GET_SCHOOL_SETTINGS, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
}


