import React, { useEffect, useState } from 'react';
import AppCard from '@crema/components/AppCard';
import AppList from '@crema/components/AppList';
import RecentFaceUnknownCell from './RecentFaceUnknownCell';
import AppScrollbar from '@crema/components/AppScrollbar';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AppMenu from '@crema/components/AppMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetDashBoardRecentFaceUnknown } from "toolkit/actions";
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Event from "socket/Event";

const RecentFaceUnknowns = ({ schoolId }) => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dataFace, setDataFace] = useState();

  useEffect(() => {
    if (dataFace) {

      const newData = [...data, dataFace];
      const sortedData = newData.sort((a, b) => b.time - a.time);
      setData(sortedData);
    }
  }, [dataFace]);

  const onGetNewRecord = (dataEvent) => {
    setDataFace(dataEvent);
  }
  
  return (
    <>
      <Event event={`recent_face_unknown_${schoolId}`} handler={(data) => onGetNewRecord(data)} />
      <AppCard
        sxStyle={{ height: '100%' }}
        contentStyle={{ paddingLeft: 0, paddingRight: 0 }}
        title={"Người lạ"}
      // action={<AppMenu />}
      >
        <AppScrollbar
          sx={{
            height: '100%',
          }}
        >
          <AppList
            data={data}
            renderRow={(face) => (
              <RecentFaceUnknownCell key={face.id} face={face} />
            )}
          />
        </AppScrollbar>
      </AppCard>
    </>

  );
};

export default RecentFaceUnknowns;

RecentFaceUnknowns.propTypes = {
  schoolId: PropTypes.string,
};