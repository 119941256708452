import { combineReducers } from 'redux';
import School from './School';
import Common from './Common';
import Settings from './Setting';
import Util from './Util';
import Device from './Device';
import User from './User';
import Camera from './Camera';
import Record from './Record';
import Category from './Category';
import Notification from './Notification';
import Dashboard from './Dashboard';
import Face from './FaceCheck';
import FaceQBS from './FaceQBS';

const reducers = () =>
  combineReducers({
    school: School,
    common: Common,
    settings: Settings,
    util: Util,
    device: Device,
    user: User,
    camera: Camera,
    record: Record,
    category: Category,
    notification: Notification,
    dashboard: Dashboard,
    face: Face,
    faceQBS: FaceQBS,
  });
export default reducers;
