import { useAuthUser } from "@crema/hooks/AuthHooks";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppInfoView from "@crema/components/AppInfoView";
import AppAnimate from "@crema/components/AppAnimate";
import AppGridContainer from "@crema/components/AppGridContainer";
import { onGetClass, onGetSchoolList } from "toolkit/actions";
import FaceUnknowns from "./Face/FaceUnknown";
import { useNavigate, useParams } from "react-router-dom";
import FaceClass from "./FaceClass";
import Faces from "./Face";
import FaceSchool from "./FaceSchool";

const FaceCheck = () => {
  const [queryParams, setQueryParams] = useState({
    name: '',
    currentPage: 0,
    limit: 99999
  });
  const take = 2;
  const { user } = useAuthUser();
  const { schoolId, classId } = useParams();
  const { roles } = user;

  const dispatch = useDispatch();
  const classList = useSelector(({ dashboard }) => dashboard.class);
  const schools = useSelector(({ school }) => school.items);

  useEffect(() => {

    schoolId && dispatch(onGetClass({
      schoolId: schoolId
    }));
    dispatch(onGetSchoolList(queryParams));
  }, [dispatch]);

  return <>

    <AppAnimate animation='transition.slideUpIn' delay={200}>
      {
        schoolId ? (
          !classId ? (
            <Faces schoolId={schoolId} classList={classList} />
          ) :
            (
              <FaceClass schoolId={schoolId} classId={classId} classList={classList} />
            ))
          :
          <FaceSchool schools={schools} />
      }
    </AppAnimate>
    <AppInfoView />
  </>
}

export default FaceCheck;