import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_FACE_QBS,
  GET_FACE_QBS_CLASS,
} from '@crema/constants/ActionTypes';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const onGetFaceQBS = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: GET_FACE_QBS, payload: payload});
  };
};
export const onGetFaceQBSClass = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: GET_FACE_QBS_CLASS, payload: payload.classId });
  };
};