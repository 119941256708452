import {
  CREATE_NEW_DEVICE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_DEVICE_DETAIL,
  GET_DEVICE_LIST,
  SHOW_ERROR_MESSAGE,
  SHOW_MESSAGE,
  UPDATED_DEVICE
} from '@crema/constants/ActionTypes';
import { getError } from '@crema/helpers/ApiHelper';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { showMessage } from './Common';

export const onGetDeviceList = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('devices', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_DEVICE_LIST, payload: data?.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onCreateDevice = (payload) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('devices', payload)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CREATE_NEW_DEVICE, payload: data.data });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['device.created'] || 'Created successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });
  };
};

export const onDeleteSelectedDevices = (deviceIds = [], schoolId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await deviceIds.reduce(async (lp, id) => {
      await lp;
      try {
        await jwtAxios.delete(`devices/${id}`);
      } catch (error) {
        dispatch({ type: SHOW_ERROR_MESSAGE, payload: `There was an error deleting the id ${id}` });
      }
    }, Promise.resolve());
    dispatch({ type: FETCH_SUCCESS });
    dispatch(onGetDeviceList({ name: '', schoolId }))
  };
};

export const onGetDeviceDetail = (deviceId = '') => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.get(`devices/${deviceId}`)).data;
      dispatch({ type: GET_DEVICE_DETAIL, payload: data });
      dispatch({ type: FETCH_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onUpdateDevice = (deviceId = '', payload = {}) => {
  const { messages } = appIntl();
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = (await jwtAxios.put(`devices/${deviceId}`, payload)).data;
      dispatch({ type: GET_DEVICE_DETAIL, payload: data });
      dispatch({ type: UPDATED_DEVICE, payload: data });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({
        type: SHOW_MESSAGE,
        payload: messages['device.created'],
      });
      dispatch(showMessage(messages['device.updatedSuccess'] || 'Updated successfully'));
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
    }
  };
};

export const onTestConnectDevices = (deviceId = '') => {
  const { messages } = appIntl();
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post(`devices/test-connect/${deviceId}`)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['device.testConnect'] || 'Test Connect successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });

  };
};
export const onGetChannelDevices = (deviceId = '') => {
  const { messages } = appIntl();
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post(`devices/sync-channel/${deviceId}`)
      .then((resp) => {
        const { data } = resp;
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: messages['device.syncChannel'] || 'Sync Channel successfully',
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: getError(error.response) });
      });
  };
};
export const onSyncFaceDevices = (deviceId = '') => {
  const { messages } = appIntl();
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await syncFaceDevices(deviceId);
    dispatch({ type: FETCH_SUCCESS });
    dispatch({
      type: SHOW_MESSAGE,
      payload: messages['device.syncFace'] || 'Sync Face successfully',
    });
    // dispatch({ type: FETCH_ERROR, payload: getError(error.response) });

  };
};

async function syncFaceDevices(deviceId, page = 0, limit = 10) {
  
  await jwtAxios
    .post(`devices/sync-face/${deviceId}/?page=${page}&limit=${limit}`)
    .then(async (resp) => {
      const { data } = resp;
      if (!data?.data?.done) {
        page = data?.data?.page + 1;
      
        await syncFaceDevices(deviceId, page, data?.data?.limit);
      }
    });

}