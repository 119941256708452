import React, { useEffect } from 'react';
import AppCard from '@crema/components/AppCard';
import AppList from '@crema/components/AppList';
import FaceCell from './FaceCell';
import AppScrollbar from '@crema/components/AppScrollbar';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AppMenu from '@crema/components/AppMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetFaceQBS } from "toolkit/actions";
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Event from "socket/Event";
import FaceMenu from './Menu';

const FaceItem = ({ schoolId, data, viewType }) => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const faces = useSelector(({ faceQBS }) => faceQBS.faces);
  
  const faceData = faces.filter(item => viewType == 'class' ? item.classId == data : item.cameraName == data) || [];

  return (
    <>
      <AppCard
        sxStyle={{ height: 1 }}
        contentStyle={{ paddingLeft: 0, paddingRight: 0 }}
        title={`${data} (${faceData.length})`}
        action={<FaceMenu schoolId={schoolId} id={data} viewType={viewType} />}
      >
        <AppScrollbar
          sx={{
            height: 250,
          }}
        >
          <AppList
            data={faceData}
            renderRow={(face) => (
              <FaceCell key={face.studentId} face={face} />
            )}
          />
        </AppScrollbar>
      </AppCard>
    </>

  );
};

export default FaceItem;

FaceItem.propTypes = {
  schoolId: PropTypes.string,
  data: PropTypes.string,
  viewType: PropTypes.string,
};
