import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const User = React.lazy(() => import('../../../modules/user'));

export const userPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
    path: [
      '/user',
      '/user/:id'
    ],
    element: <User />,
  }
];
