import { useAuthUser } from "@crema/hooks/AuthHooks";
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppInfoView from "@crema/components/AppInfoView";
import AppAnimate from "@crema/components/AppAnimate";
import AppGridContainer from "@crema/components/AppGridContainer";
import { onGetClass, onGetFaceQBS, onGetSchoolList } from "toolkit/actions";
import { useNavigate, useParams } from "react-router-dom";
import FaceClass from "./FaceClass";
import Faces from "./Face";
import FaceSchool from "./FaceSchool";
import FaceCamera from "./FaceCamera";

const FaceQBS = () => {
  const [viewType, setViewType] = useState('camera');
  const [queryParams, setQueryParams] = useState({
    name: '',
    currentPage: 0,
    limit: 99999
  });
  const take = 2;
  const { user } = useAuthUser();
  const { schoolId, type, id } = useParams();
  const { roles } = user;
  
  useEffect(() => {
    type && setViewType(type);
  }, [type]);
  
  const dispatch = useDispatch();
  const schools = useSelector(({ school }) => school.items);

  useEffect(() => {
    dispatch(onGetSchoolList(queryParams));
  }, [dispatch]);

  return <>
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      {
        schoolId ? (
          !id ? (
            <Faces schoolId={schoolId} viewType={viewType} />
          ) :
            ( viewType === 'class' ?
              (<FaceClass schoolId={schoolId} classId={id} />) :
              (<FaceCamera schoolId={schoolId} cameraId={id} />) 
            ))
          :
          <FaceSchool schools={schools} />
      }
    </AppAnimate>
    <AppInfoView />
  </>
}

export default FaceQBS;