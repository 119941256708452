import React, { useEffect, useMemo, useState } from 'react';
import { InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from 'prop-types';
import axios from '@crema/services/axios';
import SelectOption from 'component/common/select-options';
import IntlMessages from '@crema/helpers/IntlMessages';

const ProvinceSelect = ({ defaultValue, onChange }) => {
  const [cities, setCities] = useState([]);

  const getCities = async () => {
    const { results } = (await axios.get('https://vapi.vnappmob.com/api/province/')).data;
    const mapping = results.map(item => {
      return {
        label: item.province_name,
        value: item.province_id
      }
    })
    setCities(mapping);
  }

  useEffect(() => {
    getCities();
  }, []);

  return (
    <SelectOption defaultValue={defaultValue} label={<IntlMessages id='select.province' />} options={cities} onChange={(value) => onChange(value)} />
  )
}

ProvinceSelect.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
}

export default ProvinceSelect;