import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Grid } from '@mui/material';
import RecentSchoolCell from './RecentSchoolCell';

const RecentFaceSchool = ({ schools }) => {
  
  return (
    <AppGridContainer>
      {(schools || []).map((school, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
          <RecentSchoolCell data={school} />
        </Grid>
      ))}
    </AppGridContainer >

  );
};

export default RecentFaceSchool;

RecentFaceSchool.propTypes = {
  schools: PropTypes.array,
};
