import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
const School = React.lazy(() => import('../../../modules/school'));
export const schoolPagesConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer],
    path: [
      '/school',
      '/school/:id'
    ],
    element: <School />,
  }
];
