import {
  GET_DASHBOARD_RECENT_FACE,
  GET_CLASS_LIST
} from '@crema/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  recentFaces: [],
  class: []
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_DASHBOARD_RECENT_FACE, (state, action) => {
      const { name, data, count } = action.payload;
      const index = state.recentFaces.findIndex(item => item.key === name);
      if (index > -1) {
        state.recentFaces[index].items = data;
        state.recentFaces[index].count = count;
      } else {
        state.recentFaces.push({
          key: name,
          items: data,
          count: count
        });
      }
    }).addCase(GET_CLASS_LIST, (state, action) => {
      state.class = action.payload ?? [];
    })
});

export default dashboardReducer;
