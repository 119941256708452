import React, { useEffect } from 'react';
import AppCard from '@crema/components/AppCard';
import AppList from '@crema/components/AppList';
import AppScrollbar from '@crema/components/AppScrollbar';
import PropTypes from 'prop-types';
import FaceCell from './FaceCell';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Grid } from '@mui/material';

const FaceCameraItem = ({  data }) => {
  return (
    <AppGridContainer>
      {data.length > 0
        ? data.map((item, index) =>
          <Grid item xs={6} sm={3} md={3} lg={2} xl={2} key={item.id}>
            <FaceCell key={item.studentId} face={item} />
          </Grid>
        )
        : null}
    </AppGridContainer>

  );
};

export default FaceCameraItem;

FaceCameraItem.propTypes = {
  data: PropTypes.array,
};
