import React, { useEffect } from 'react';
import AppCard from '@crema/components/AppCard';
import AppList from '@crema/components/AppList';
import RecentFaceCell from './RecentFaceCell';
import AppScrollbar from '@crema/components/AppScrollbar';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AppMenu from '@crema/components/AppMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetDashBoardRecentFace } from "toolkit/actions";
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Event from "socket/Event";
import RecentFaceMenu from './Menu';

const RecentFaceItem = ({ schoolId, data }) => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const recentFaces = useSelector(({ dashboard }) => dashboard.recentFaces);

  const recentFaceData = recentFaces.find(item => item.key === data.name)?.items || [];
  const count = recentFaces.find(item => item.key === data.name)?.count || 0;

  useEffect(() => {
    dispatch(onGetDashBoardRecentFace({
      schoolId: user.schoolId,
      classId: data.name
    }));
  }, [dispatch]);

  const onGetNewRecord = (dataEvent) => {
    dispatch(onGetDashBoardRecentFace({
      schoolId: user.schoolId,
      classId: data.name
    }));

  }
  return (
    <>
      <Event event={`recent_face_${schoolId}_${data.name}`} handler={(data) => onGetNewRecord(data)} />

      <AppCard
        sxStyle={{ height: 1 }}
        contentStyle={{ paddingLeft: 0, paddingRight: 0 }}
        title={`${data.name} (${count}/${data.count})`}
        action={<RecentFaceMenu schoolId={schoolId} classId={data.name} />}
      >
        <AppScrollbar
          sx={{
            height: 250,
          }}
        >
          <AppList
            data={recentFaceData}
            renderRow={(face) => (
              <RecentFaceCell key={face.id} face={face} />
            )}
          />
        </AppScrollbar>
      </AppCard>
    </>

  );
};

export default RecentFaceItem;

RecentFaceItem.propTypes = {
  schoolId: PropTypes.string,
  data: PropTypes.array,
};
