import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FaceItem from './FaceItem';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Box, Grid, Typography } from '@mui/material';
import AppTooltip from "@crema/components/AppTooltip";
import IntlMessages from "@crema/helpers/IntlMessages";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fonts } from "@crema/constants/AppEnums";
import { useNavigate } from 'react-router-dom';
import Event from "socket/Event";
import { useDispatch, useSelector } from "react-redux";
import { onGetClass, onGetFaceQBS, onGetSchoolList } from "toolkit/actions";

const Faces = ({ schoolId, viewType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const classList = useSelector(({ faceQBS }) => faceQBS.class);
  const cameraList = useSelector(({ faceQBS }) => faceQBS.cameras);
  
  const onClickBackButton = () => {
    navigate(-1);
  };
  const onGetNew = (dataEvent) => {
    dispatch(onGetFaceQBS(dataEvent));

  }
  return (
    <>
      <Event event={`qbs_face_${schoolId}`} handler={(data) => onGetNew(data)} />
      <Box
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          display: 'flex',
          marginBottom: 6
        }}
        component='span'
        mr={{ xs: 2, sm: 4 }}
        onClick={onClickBackButton}
      >
        <AppTooltip title={<IntlMessages id='common.back' />}>
          <ArrowBackIcon
            sx={{
              color: 'text.secondary',
            }}
          />
        </AppTooltip>

        <Typography
          component='h2'
          variant='h2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 3.5,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
            mb: { xs: 3, sm: 0 },
          }}
        >
          {<IntlMessages id='common.back' />}
        </Typography>
      </Box>
      <AppGridContainer>
        {
          viewType === 'class' ?
          classList.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <FaceItem schoolId={schoolId} data={item} viewType={viewType} />
            </Grid>
          ))
          :
          cameraList.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <FaceItem schoolId={schoolId} data={item} viewType={viewType} />
            </Grid>
          ))
        }
      </AppGridContainer>

    </>

  );
};

export default Faces;

Faces.propTypes = {
  schoolId: PropTypes.string,
  viewType: PropTypes.string,
};
