import React, { useEffect } from 'react';
import AppCard from '@crema/components/AppCard';
import AppList from '@crema/components/AppList';
import FaceCell from './FaceCell';
import AppScrollbar from '@crema/components/AppScrollbar';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AppMenu from '@crema/components/AppMenu';
import { useDispatch, useSelector } from 'react-redux';
import { onGetFaceCheck } from "toolkit/actions";
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Event from "socket/Event";
import FaceMenu from './Menu';

const FaceItem = ({ schoolId, data }) => {
  const { messages } = useIntl();
  const { user } = useAuthUser();
  const dispatch = useDispatch();
  const faces = useSelector(({ face }) => face.faces);

  const faceData = faces.find(item => item.key === data.name)?.items || [];

  useEffect(() => {
    dispatch(onGetFaceCheck({
      schoolId: user.schoolId,
      classId: data.name
    }));
  }, [dispatch]);

  const onGetNewRecord = (dataEvent) => {
    dispatch(onGetFaceCheck({
      schoolId: user.schoolId,
      classId: data.name
    }));

  }
  return (
    <>
      <Event event={`recent_face_${schoolId}_${data.name}`} handler={(data) => onGetNewRecord(data)} />

      <AppCard
        sxStyle={{ height: 1 }}
        contentStyle={{ paddingLeft: 0, paddingRight: 0 }}
        title={`${data.name} (${data.count})`}
        action={<FaceMenu schoolId={schoolId} classId={data.name} />}
      >
        <AppScrollbar
          sx={{
            height: 250,
          }}
        >
          <AppList
            data={faceData}
            renderRow={(face) => (
              <FaceCell key={face.id} face={face} />
            )}
          />
        </AppScrollbar>
      </AppCard>
    </>

  );
};

export default FaceItem;

FaceItem.propTypes = {
  schoolId: PropTypes.string,
  data: PropTypes.array,
};
