import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, Navigate } from 'react-router-dom';

const RecentFaceMenu = ({ schoolId, viewType, id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleViewDetail = () => {
    window.location.href = `/face-qbs/${schoolId}/${viewType}/${id}`;
    setAnchorEl(null);
  }
  return (
    <>
      <IconButton
        style={{ height: 30, width: 30 }}
        aria-label='more'
        onClick={handleClick}
        size='large'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key={"view-detail"}
          onClick={handleViewDetail}
        >
          {/* <Link to={'/dashboard/' + id}> */}
          {viewType == "camera" ? "Xem camera" : "Xem lớp"}
          {/* </Link> */}
        </MenuItem>
      </Menu>
    </>
  );
};

export default RecentFaceMenu;

RecentFaceMenu.propTypes = {
  schoolId: PropTypes.string,
  id: PropTypes.string,
  viewType: PropTypes.string,
};