import { RoutePermittedRole } from '@crema/constants/AppEnums';
import React from 'react';
import { BiHome, BiUser, BiUserCircle, BiSolidDashboard, BiAnalyse, BiCameraMovie, BiCamera, BiCameraHome, BiCategory, BiUnite } from 'react-icons/bi';

const routesConfig = [
  {
    id: 'account',
    title: 'Account',
    messageId: 'sidebar.account',
    type: 'item',
    icon: <BiUserCircle />,
    url: '/my-profile',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'item',
    icon: <BiSolidDashboard />,
    url: '/dashboard/',
    permittedRole: [RoutePermittedRole.SchoolAdmin, RoutePermittedRole.Dealer, RoutePermittedRole.Admin, RoutePermittedRole.User],
  },
  {
    id: 'faceCheck',
    title: 'Face Checks',
    messageId: 'sidebar.face-check',
    type: 'item',
    icon: <BiSolidDashboard />,
    url: '/face-check/',
    permittedRole: [RoutePermittedRole.SchoolAdmin, RoutePermittedRole.Dealer, RoutePermittedRole.Admin, RoutePermittedRole.User],
  },
  {
    id: 'faceQBS',
    title: 'Face QBSs',
    messageId: 'sidebar.face-qbs',
    type: 'item',
    icon: <BiSolidDashboard />,
    url: '/face-qbs/',
    permittedRole: [RoutePermittedRole.SchoolAdmin, RoutePermittedRole.Dealer, RoutePermittedRole.Admin, RoutePermittedRole.User],
  },
  {
    id: 'school',
    title: 'Listing',
    messageId: 'sidebar.school.list',
    type: 'item',
    icon: <BiHome />,
    url: '/school/',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer],
  },
  {
    id: 'user',
    title: 'User Listing',
    messageId: 'sidebar.user.list',
    type: 'item',
    icon: <BiUser />,
    url: '/user/',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Dealer, RoutePermittedRole.SchoolAdmin],
  },
];
export default routesConfig;
