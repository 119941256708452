import React, { useEffect, useMemo, useState } from 'react';
import { InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from 'prop-types';
import axios from '@crema/services/axios';
import SelectOption from 'component/common/select-options';
import IntlMessages from '@crema/helpers/IntlMessages';

const DistrictSelect = ({ city, defaultValue, onChange }) => {
  const [cities, setCities] = useState([]);

  const getDistrict = async () => {
    const { results } = (await axios.get(`https://vapi.vnappmob.com/api/province/district/${city}`)).data;
    const mapping = results.map(item => {
      return {
        label: item.district_name,
        value: item.district_id
      }
    })
    setCities(mapping);
  }

  useEffect(() => {
    if (city) {
      getDistrict();
    }
  }, [city]);

  return (
    <SelectOption defaultValue={defaultValue} label={<IntlMessages id='select.district' />} options={cities} onChange={(value) => onChange(value)} />
  )
}

DistrictSelect.propTypes = {
  defaultValue: PropTypes.string,
  city: PropTypes.string,
  onChange: PropTypes.func
}

export default DistrictSelect;