import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_FACE_CHECK
} from '@crema/constants/ActionTypes';
import { appIntl } from '@crema/helpers/Common';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const onGetFaceCheck = (payload, showLoading = true) => {
  const { messages } = appIntl();
  return (dispatch) => {
    if (showLoading) {
      dispatch({ type: FETCH_START });
    }
    jwtAxios
      .get('face-checks', {
        params: {
          ...payload
        },
      })
      .then((resp) => {
        const { data } = resp;
        if ((data.status || data.code) === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_FACE_CHECK, payload: { name: payload.classId, data: data?.data.items, count: data?.data.count } });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};