import React from 'react';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Fonts } from '@crema/constants/AppEnums';
import { getFormattedDate } from '@crema/helpers/DateHelper';

const FaceCell = ({ face }) => {
  return (
    face &&
    <Box
      sx={{
        display: 'flex',
        padding: '3px 20px',
      }}
      className='item-hover'
    >
      <Avatar
        sx={{
          width: 50,
          height: 50,
          mr: 3.5,
          border: `solid 2px ${face?.job !== "Người lạ" ? "rgba(41, 151, 255, 0.7)" : "red"}`,
        }}
        src={`${face?.image}`}
      />
      <Box
        sx={{
          fontSize: 14,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: (theme) => theme.palette.text.primary,
              width: '60%',
              textTransform: 'capitalize',
            }}
          >
            {`${face?.job !== "Người lạ" ? face?.studentId : "-"}`}
            
            <Typography
              sx={{
                fontSize: '1rem',
                mb: 1,
                color: `${face?.job !== "Người lạ" ? "rgba(41, 151, 255, 0.7)" : "red"}`,
              }}
            >
              {face?.job}
            </Typography>
          </Typography>
          <Box
            sx={{
              color: (theme) => theme.palette.text.secondary,
              ml: 'auto',
              textAlign: 'right',
            }}
          >
            <Typography
              sx={{
                fontSize: '12',
                fontWeight: 'bold',
              }}
            >
              {getFormattedDate(face.time, 'DD/MM/YYYY HH:mm:ss','DDMMyyyyHHmmss')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaceCell;

FaceCell.propTypes = {
  face: PropTypes.object.isRequired,
};
