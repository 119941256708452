import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from '@crema/services/axios';
import SelectOption from 'component/common/select-options';
import IntlMessages from '@crema/helpers/IntlMessages';

const WardSelect = ({ district, defaultValue, onChange }) => {
  const [cities, setCities] = useState([]);

  const getWard = async () => {
    const { results } = (await axios.get(`https://vapi.vnappmob.com/api/province/ward/${district}`)).data;
    const mapping = results.map(item => {
      return {
        label: item.ward_name,
        value: item.ward_id
      }
    })
    setCities(mapping);
  }

  useEffect(() => {
    if (district) {
      getWard();
    }
  }, [district]);

  return (
    <SelectOption defaultValue={defaultValue} label={<IntlMessages id='select.ward' />} options={cities} onChange={(value) => onChange(value)} />
  )
}

WardSelect.propTypes = {
  defaultValue: PropTypes.string,
  district: PropTypes.string,
  onChange: PropTypes.func
}

export default WardSelect;