import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RecentFaceItem from './RecentFaceItem';
import RecentFaceUnknowns from './RecentFaceUnknown';
import AppGridContainer from '@crema/components/AppGridContainer';
import { Box, Grid ,Typography} from '@mui/material';
import AppTooltip from "@crema/components/AppTooltip";
import IntlMessages from "@crema/helpers/IntlMessages";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fonts } from "@crema/constants/AppEnums";
import { useNavigate } from 'react-router-dom';

const RecentFaces = ({ schoolId, classList }) => {

  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate(-1);
  };
  return (

    <>
    <Box
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          display: 'flex',
          marginBottom:6
        }}
        component='span'
        mr={{ xs: 2, sm: 4 }}
        onClick={onClickBackButton}
      >
        <AppTooltip title={<IntlMessages id='common.back' />}>
          <ArrowBackIcon
            sx={{
              color: 'text.secondary',
            }}
          />
        </AppTooltip>

        <Typography
          component='h2'
          variant='h2'
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 3.5,
            fontSize: 16,
            fontWeight: Fonts.MEDIUM,
            mb: { xs: 3, sm: 0 },
          }}
        >
          {<IntlMessages id='common.back' />}
        </Typography>
      </Box>
      <AppGridContainer>
        <Grid item xs={12} sm={6} md={7} lg={8} xl={9} key={-1}>
          <AppGridContainer>
            {
              classList?.items?.map((item, index) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                  <RecentFaceItem schoolId={schoolId} data={item} />
                </Grid>
              ))
            }
          </AppGridContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={-1}>
          <RecentFaceUnknowns schoolId={schoolId} />
        </Grid>
      </AppGridContainer>

    </>

  );
};

export default RecentFaces;

RecentFaces.propTypes = {
  schoolId: PropTypes.string,
  classList: PropTypes.array,
};
